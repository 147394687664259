import React, { useState } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import submitStandardForm from '/src/functions/forms/submitStandardForm'

const Alert = loadable(() => import('/src/components/Basic/Alert'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const FormRow = loadable(() => import('/src/components/Form/FormRow'))
const RequiredText = loadable(() => import('/src/components/Form/Required/RequiredText'))
const Select = loadable(() => import('/src/components/Form/Select'))
const SubmitButton = loadable(() => import('/src/components/Form/SubmitButton'))
const TextAreaField = loadable(() => import('/src/components/Form/TextAreaField'))
const TextField = loadable(() => import('/src/components/Form/TextField'))

const validationSchema = Yup.object({
    name: Yup.string().required('Full name is required'),
    email: Yup.string().email('Email address is not valid').required('Email is required'),
    message: Yup.string().required('This field is required')
})

const PRPortalForm = ({ disclaimer }) => {
    const [isSubmitted, setSubmitted] = useState(false)

    return (
        <Formik
            initialValues={{
                name: '',
                website: '',
                email: '',
                message: ''
            }}
            onSubmit={async (values, actions) => {
                const firebaseData = {
                    createdAt: new Date(),
                    name: values.name,
                    website: values.website,
                    email: values.email,
                    message: values.message
                }

                const submitOptions = {
                    actions: actions,
                    firebaseCollection: 'pr-form',
                    firebaseData: firebaseData,
                    confirmationEmail: process.env.GATSBY_PR_EMAIL_ADDRESS,
                    emailTemplate: 'pr-form-template'
                }

                setSubmitted(true)

                await submitStandardForm(submitOptions)
            }}
            validationSchema={validationSchema}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <Form>
                    <RequiredText />

                    <FormRow>
                        <TextField
                            label={'Full name'}
                            name={'name'}
                            placeholder={'e.g. Emma Jones'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            required
                        />
                    </FormRow>

                    <FormRow>
                        <TextField
                            label={'Website'}
                            name={'website'}
                            type={'website'}
                            placeholder={'e.g. example.com'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                        />
                    </FormRow>

                    <FormRow>
                        <TextField
                            label={'Email address'}
                            name={'email'}
                            type={'email'}
                            placeholder={'e.g. name@email.com'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            required
                        />
                    </FormRow>

                    <FormRow>
                        <TextAreaField
                            label={'Your enquiry'}
                            name={'message'}
                            placeholder={'Type your enquiry here...'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            required
                        />
                    </FormRow>

                    {disclaimer && <Alert content={disclaimer} />}

                    <CtaContainer align={'right'}>
                        <SubmitButton
                            initialText={'Send enquiry'}
                            sentText={'Enquiry sent!'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            disabled={!(isValid && dirty)}
                        />
                    </CtaContainer>
                </Form>
            )}
        </Formik>
    )
}

PRPortalForm.propTypes = {
    /**
     * Disclaimer
     */
    disclaimer: PropTypes.string
}

PRPortalForm.defaultProps = {
    disclaimer: '<p>This is the disclaimer message</p>'
}

export default PRPortalForm
